body {
  height: 100vh;
  padding: 0;
  margin: 0;
  overflow: hidden;
  top: 70%;
}

.App {
  text-align: center;
  background-color: #3A3B3C;
}

.App-body {
  margin: 0;
  position: relative;
  top: 70%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.swiper-container {
  height: 100vh;
  align-self: stretch;
}

.swiper-horizontal {
  height: 100vh;
}

.TimeContainer {
  padding-top: 15vh;
}

.Time {
  color: #ffffff;
  font-size: 18px;
  font-family: monospace;
}
