/*  If you use some crazy Z-Index values, and your modal is shown
behind something, change the Z-Index values in this file.

I usually don't have more than 20 custom Z-Index levels
in my application, the HTML hierarchy is enough in most cases.

If you have an element with a "z-index: 999999;" in your APP
component, it will show behind the modal because of the hierarchy
(in theory), but anything can happen, just in case.*/

.modal-background {
    position: fixed;
    top: 0;
    left: 0;

    width: 100vw;
    height: 100vh;

    cursor: auto;

    background-color: rgba(white, .6);
    backdrop-filter: blur(7px);
    z-index: 16;

    &.dark {
        background-color: rgba(black, .6);
    }
}

.modal {
    position: fixed;
    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);

    max-width: calc(100vw - 8rem);
    max-height: calc(100vh - 4rem);

    overflow: auto;
    z-index: 17;

    &>div,
    &>img {
        margin: auto;

        max-width: 100%;
        max-height: calc(100vh - 4rem);
    }

    /* Temporary style for the container,
        you can delete it, it's just the dashed border and stuff.,
        
        You'll normally want a container without styles, no background 
        or padding, so you can put your own Card component, or Forms,
        or Login Screen, etc.
    -------------- */
    font-weight: 400;
    color: black;
    background: rgba(black, 0.1);

    border: 0.2em dashed rgba(black, 0.25);
    border-radius: 1em;
    padding: 2rem;

    &.dark {
        color: white;
        background: rgba(white, 0.1);
        border: 0.2em dashed white;
    }

    /* --------------
    /* Temporary style for the container */

}

.modal-close-button {
    position: fixed;
    top: 0;
    right: 0;

    font-size: 1.5rem;
    color: black;

    background: none;
    border: none;

    margin: 0.5rem;

    cursor: pointer;
    z-index: 18;

    transition: transform 0.15s;

    &:hover {
        transform: scale(1.5);
    }

    &.dark {
        color: white;
    }
}